<template>
  <div class="FridgeSetup">
    <v-card dark color="transparent">
      <v-card-text>
        <p class="prm">
          Az alábbi felületen állítsa be azokat a kihelyezéseket (hűtőket)
          amelyek az <b>Ön</b> boltjában megtalálhatók. Minden hűtő
          kiválasztását követően a hűtőn található QR kód lefényképezése
          szükséges!
        </p>
      </v-card-text>
    </v-card>

    <v-list dark color="secondary">
      <v-list-item
        class="pt-3 pb-3"
        dark
        v-for="item in items"
        :key="item.id"
        style="background-color: #1b1b1b; margin-bottom: 5px"
      >
        <img class="mr-2" src="../assets/svg/fridge.svg" />{{
          item.fridgeDisplayType.name
        }}
        <v-btn
          @click="deleteItem(item)"
          color="primary"
          class="ml-auto"
          small
          fab
          ><v-icon small>mdi-close</v-icon></v-btn
        >
      </v-list-item>
    </v-list>

    <v-subheader dark>Elérhető hűtők</v-subheader>
    <v-list dark color="secondary">
      <v-list-item
        class="pt-3 pb-3"
        dark
        v-for="item in filteredAvailableItems"
        :key="item.id"
        style="background-color: #1b1b1b; margin-bottom: 5px"
      >
        <img class="mr-2" src="../assets/svg/fridge.svg" />{{ item.name }}
        <v-btn
          @click="addItem(item)"
          color="secondary"
          class="ml-auto"
          small
          fab
          ><v-icon small>mdi-plus</v-icon></v-btn
        >
      </v-list-item>
    </v-list>

    <v-dialog v-model="isQR">
      <v-card dark>
        <v-card-title><v-icon>mdi-qr</v-icon></v-card-title>
        <v-card-text>
          <qrcode-stream
            v-if="isQR && activeItem !== null"
            @decode="onDecode"
          ></qrcode-stream>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="handleNoQR()">NINCS QR KÓD</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import Pager from "../components/Pager.vue";
import { API_BASE } from "../util/constants";
import { getLoggedInHeader, toQueryString } from "../util/functions.js";
import { QrcodeStream } from "vue-qrcode-reader";
import eventBus from "../eventBus.js";
import messageBus from "../messageBus.js";
export default {
  name: "FridgeSetup",
  components: { QrcodeStream },
  created() {
    this.getList();
  },
  data() {
    return {
      items: [],
      availableItems: [],
      activeItem: null,
      isQR: false,
    };
  },
  methods: {
    getList() {
      this.$store
        .dispatch("getCollection", {
          end: `client/fridge-displays`,
          data: null,
        })
        .then((res) => {
          this.items = res;
          this.getAll();
        });
    },
    getAll() {
      this.$store
        .dispatch("getCollection", {
          end: `client/fridge-display-types`,
          data: null,
        })
        .then((res) => {
          this.availableItems = res;
        });
    },
    deleteItem(item) {
      const ID = item.id;
      this.$store
        .dispatch("removeItem", {
          end: `client/fridge-displays/${ID}`,
          data: null,
        })
        .then((res) => {
          this.getList();
        });
    },
    addItem(item) {
      // trigger qr detection
      this.activeItem = item;
      this.isQR = true;
    },
    onDecode(string) {
      this.submit(string);
    },
    handleNoQR() {
      this.submit("NO SCAN");
    },
    async submit(qr) {
      const requestOptions = {
        method: "POST",
        headers: getLoggedInHeader(localStorage.getItem("token")),
        redirect: "follow",
      };
      try {
        eventBus.$emit("LOAD_ON");
        let response = await fetch(
          `${API_BASE}client/fridge-displays/fridge-display-types/${this.activeItem.id}?qrCode=${qr}`,
          requestOptions
        );

        if (response.ok) {
          const jsonResponse = await response.json();
          messageBus.$emit("onNewMessage", {
            type: "success",
            label: this.$t("message.success"),
          });
          this.getList();
        } else {
          messageBus.$emit("onNewMessage", {
            type: "error",
            label: await response.text(),
          });
        }
      } catch (error) {
        messageBus.$emit("onNewMessage", {
          type: "error",
          label: error,
        });
        console.log(error);
      } finally {
        eventBus.$emit("LOAD_OFF");
        this.isQR = false;
      }
    },
    comparer(otherArray) {
      // fridge list diff added/available
      return function (current) {
        return (
          otherArray.filter(function (other) {
            return other.fridgeDisplayType.id === current.id;
          }).length == 0
        );
      };
    },
  },
  computed: {
    filteredAvailableItems() {
      return this.availableItems.filter(this.comparer(this.items));
    },
  },
  watch: {},
};
</script>

<style scoped>
/*.row {
  margin: -12px;
}
.row + .row {
  margin: -24px -12px;
}*/
</style>
